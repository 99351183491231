import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Box, Button, IconButton, Toolbar, Typography } from '@mui/material';
import Logo from '../Logo';

function Footer() {
  const goTo = (id: string) => {
    let elem = document.getElementById(id);
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <Box
      sx={{
        flexGrow: 1
      }}
      mb={5}
    >
      <Toolbar
        sx={{
          color: '#fff',
          justifyContent: 'space-between',
          marginTop: 'auto'
        }}
      >
        <Box
          pl="20px"
          component={Button}
          sx={{
            color: 'inherit',
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
          onClick={() => {
            goTo('home');
          }}
        >
          <Logo variant="text-right" size="l" />
        </Box>

        <Typography
          variant="h6"
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            marginLeft: '20px',
            justifyContent: { xs: 'center', md: 'flex-start' }
          }}
        >
          <IconButton
            aria-label="twitter"
            color="inherit"
            href="https://twitter.com/meetavaai"
            target="_blank"
            sx={{ marginRight: '5px' }}
          >
            <TwitterIcon sx={{ color: 'black' }} />
          </IconButton>
          <IconButton
            aria-label="linkedin"
            color="inherit"
            href="https://www.linkedin.com/company/stoicsoftware/"
            target="_blank"
            sx={{ marginRight: '5px' }}
          >
            <LinkedInIcon sx={{ color: 'black' }} />
          </IconButton>
          <IconButton
            aria-label="email"
            color="inherit"
            href="mailto:info@meetava.ai"
            target="_blank"
            sx={{ marginRight: '5px' }}
          >
            <EmailIcon sx={{ color: 'black' }} />
          </IconButton>
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: 'black',
            marginRight: '20px',
            display: { xs: 'none', sm: 'block' }
          }}
        >
          {`© ${new Date().getFullYear()} Stoic Software LLC, All Rights Reserved`}
        </Typography>
      </Toolbar>
      <Typography
        variant="body1"
        sx={{
          color: 'black',
          padding: '0px 20px',
          paddingTop: '20px',
          textAlign: 'center',
          display: { xs: 'block', sm: 'none' }
        }}
      >
        {`© ${new Date().getFullYear()} Stoic Software LLC, All Rights Reserved`}
      </Typography>
    </Box>
  );
}

export default Footer;
