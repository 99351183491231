import { Box, Button, List, ListItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function HeaderMenu() {
  const location = useLocation();
  const [hide, setHide] = useState(false);
  const goTo = (id: string) => {
    let elem = document.getElementById(id);
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }
  };

  //if url contains 'privacy-policy' or 'terms-of-service' hide menu
  useEffect(() => {
    if (location.pathname.includes('privacy')) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, [location]);

  const navItemStyles = {
    color: '#223354',
    fontSize: {
      md: '1rem',
      xl: '1.5rem'
    },
    fontWeight: 'normal',
    padding: '0px 30px',
    position: 'relative',
    backgroundColor: 'transparent', // Assuming this is your regular background color
    '&:hover': {
      backgroundColor: 'transparent' // Make hover color same as regular color
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: -5,
      left: 0,
      right: 0,
      height: '2px',
      backgroundColor: '#0C7DBC',
      transform: 'scaleX(0)',
      transition: 'transform 0.3s ease-in-out'
    },
    '&:hover::after': {
      transform: 'scaleX(1)'
    }
  };
  return (
    <>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: hide ? 'none' : 'flex'
          },
          justifyContent: 'center'
        }}
      >
        <List disablePadding component={Box} display="flex">
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            sx={{ marginLeft: '30px' }}
          >
            <Button
              sx={navItemStyles}
              onClick={() => {
                goTo('benefits');
              }}
            >
              Benefits
            </Button>
          </ListItem>
          <ListItem classes={{ root: 'MuiListItem-indicators' }}>
            <Button
              sx={navItemStyles}
              onClick={() => {
                goTo('faq');
              }}
            >
              FAQ
            </Button>
          </ListItem>
          <ListItem classes={{ root: 'MuiListItem-indicators' }}>
            <Button
              sx={navItemStyles}
              href="mailto:info@meetava.ai"
              target="_blank"
            >
              Contact
            </Button>
          </ListItem>
        </List>
      </Box>
    </>
  );
}

export default HeaderMenu;
