import {
  Box,
  Button,
  Slide,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { FrontendRoutes } from 'common/types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const CookieConsent: React.FC = () => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    setOpen(true);

    const hasConsented = localStorage.getItem('cookieConsent');
    if (!hasConsented) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [open]);

  const handleClose = () => {
    console.log('handleClose');
    localStorage.setItem('cookieConsent', 'true');
    setOpen(false);
  };

  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <Box
        position="fixed"
        bottom={10}
        right={20}
        bgcolor="white"
        boxShadow="0px 0px 15px rgba(0, 0, 0, 0.2)"
        borderRadius={'5px'}
        p={2}
        display="flex"
        sx={{ zIndex: 5 }}
        flexDirection="column"
      >
        <Typography mt={1} variant="h6" fontWeight="normal">
          We use cookies to enhance the user experience.
        </Typography>
        <Typography mb={2} variant="h6" fontWeight="normal">
          By using our website you agree to our{' '}
          <Link to={FrontendRoutes.PRIVACY}>Privacy Policy</Link>.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{
            backgroundImage:
              'linear-gradient(90deg, rgba(84,187,203,1) 0%, rgba(12,125,188,1) 100%)'
          }}
          onClick={() => handleClose()}
        >
          Accept
        </Button>
      </Box>
    </Slide>
  );
};

export default CookieConsent;
