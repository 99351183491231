import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';

import Logo from 'app/components/Logo';
import { FrontendRoutes } from 'common/types';
import { Link } from 'react-router-dom';
import HeaderMenu from './Menu';

function Header() {
  const theme = useTheme();
  const isMediumToLarge = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLessThanMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  let size: 'm' | 's' | 'xl' | 'l' = 'm';
  if (isLessThanMedium) {
    size = 'l';
  } else if (isXl) {
    size = 'xl';
  } else if (isLarge) {
    size = 'l';
  } else if (isMediumToLarge) {
    size = 'l';
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        height: theme.header.height,
        color: theme.header.textColor,
        padding: {
          xs: theme.spacing(1, 3),
          sm: theme.spacing(1.5, 4),
          md: theme.spacing(2, 4),
          lg: theme.spacing(4, 5)
        },
        zIndex: theme.zIndex.drawer + 1,
        justifyContent: 'space-between',
        width: '100%'
      }}
      id={'home'}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <Box
          component={Link}
          sx={{
            color: 'inherit',
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
          to={FrontendRoutes.HOME}
        >
          <Logo size={size} variant="text-right" />
        </Box>

        <HeaderMenu />
      </Stack>
    </Box>
  );
}

export default Header;
