import { CssBaseline } from '@mui/material';
import CookieConsent from 'app/components/CookieConsent';
import { SnackbarProvider } from 'notistack';
import { useRoutes } from 'react-router-dom';
import router from './router';
import { ThemeProviderWrapper as ThemeProvider } from './theme/ThemeProvider';

function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <SnackbarProvider
        maxSnack={6}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <CssBaseline />
        {content}
        <CookieConsent />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
export default App;
