import { Box } from '@mui/material';
import Lottie from 'lottie-react';
import animationData from '../../../common/lotties/LogoLoading.json';

interface LogoLoadingProps {
  size: 's' | 'm' | 'l' | 'xl';
}

function LogoLoading(props: LogoLoadingProps) {
  let width = 0;
  switch (props.size) {
    case 's':
      width = 50;
      break;
    case 'm':
      width = 100;
      break;
    case 'l':
      width = 200;
      break;
    case 'xl':
      width = 300;
      break;
    default:
      width = 60;
  }

  return (
    <Box>
      <Lottie
        animationData={animationData}
        autoplay
        loop
        style={{ width: width }}
      />
    </Box>
  );
}

export default LogoLoading;
