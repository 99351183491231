import { Box, Container } from '@mui/material';
import Footer from 'app/components/Footer';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';

const policy = `
# Privacy Policy

1. **Introduction**
This Privacy Policy outlines how Stoic Software, LLC ("we," "us," or "our") collects, uses, and protects the personal information of users ("you") who access and use our Ava product. By using our product, you agree to the collection and use of your information in accordance with this policy.
2. **Information Collection and Use**
We collect various types of information to provide and improve our product, including:
    
    a. Personal Information: When you register for an account or interact with our product, we may collect your name, email address, phone number, mailing address, and other personal information.
    
    b. Usage Data: We collect information on how you use our product, such as the pages you visit, time spent on the site, and any errors you may encounter. This information may include your IP address, browser type, operating system, and device information.
    
    c. Cookies: We use cookies and similar technologies to enhance your experience, track your activity, and analyze usage patterns. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our product.
    
3. **Information Sharing and Disclosure**
We may share your information with third-party service providers to perform tasks on our behalf, such as payment processing, data analysis, and customer support. We require these service providers to maintain the confidentiality of your information and not use it for any purpose other than to perform the services we request.
    
    We may also disclose your personal information if required by law, in response to legal requests, or when we believe disclosure is necessary to protect our rights or the rights of others.
    
4. **Security**
We implement industry-standard security measures to protect your personal information. These measures include encryption, secure servers, and access controls. However, no method of electronic storage or transmission is 100% secure, and we cannot guarantee absolute security.
5. **Data Retention**
We will retain your personal information for as long as necessary to fulfill the purposes for which we collected it, comply with legal obligations, resolve disputes, and enforce our agreements.
6. **Children's Privacy**
Our product is not intended for use by anyone under the age of 13. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information, please contact us, and we will take steps to remove such information from our systems.
7. **International Transfers**
Your information may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction, where data protection laws may be different from those in your jurisdiction. By using our product, you consent to the transfer of your information to such locations.
8. **Your Rights**
You have the right to access, correct, update, or delete your personal information. You can do so by contacting us at the email address provided below. We may ask you to verify your identity before processing your request.
9. **Changes to This Privacy Policy**
We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the updated policy on our website, and we encourage you to review this policy periodically.
10. **Governing Law and Jurisdiction**
This Privacy Policy shall be governed by and construed in accordance with the laws of Pennsylvania, United States. Any disputes arising from this policy will be resolved exclusively in the courts of Pennsylvania.
11. **Contact Us**
If you have any questions or concerns about this Privacy Policy, please contact us at legal@stoicsoftware.net.
`;

export const PrivacyPolicy = () => {
  return (
    <>
      <Container
        sx={{
          my: 4
        }}
        maxWidth="md"
      >
        <ReactMarkdown children={policy} remarkPlugins={[remarkGfm]} />
        <Box pt={4}>
          <Footer />
        </Box>
      </Container>
    </>
  );
};
