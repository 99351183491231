export * from './Routes';
export * from './integration-types';

export interface RootState {
  // [INSERT NEW REDUCER KEY ABOVE] < Needed for generating containers seamlessly
}

export interface UserCredentials {
  username: string;
  password: string;
}

export enum RoleGroup {
  ALL = 'all',
  BASIC = 'basic'
}

export enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export interface AppRoute {
  path: string;
  method: HttpMethods;
  allowedRoles?: UserRole[];
}

export type FrontendUser = Pick<
  User,
  | 'userId'
  | 'orgId'
  | 'email'
  | 'role'
  | 'status'
  | 'name'
  | 'language'
  | 'notificationSettings'
  | 'accessLogs'
  | 'createdAt'
  | 'updatedAt'
>;
export interface User {
  userId: string;
  role: UserRole;
  name: FullName;
  orgId?: string;
  email: string;
  password: string;
  connectionIds: string[];
  customerId?: string;
  status: AccountStatus;
  language: Language;
  verification: Verification;
  notificationSettings: NotificationSettings;
  accessLogs: AccessLog[];
  createdAt: string;
  updatedAt: string;
}

export enum UserRole {
  USER = 'user',
  ADMIN = 'admin'
}

export interface NotificationSettings {
  newsletterEmail: boolean;
  salesEmail: boolean;
}

export interface AccessLog {
  browser: string;
  operatingSystem: string;
  ipAddress: string;
  country: string;
  lastAccessedAt: string;
}

export enum AccountStatus {
  UNVERIFIED = 'unverified',
  VERIFIED = 'verified'
}

export interface Verification {
  code: string;
  createdAt: string;
  expiresAt: string;
}

export interface FullName {
  firstName: string;
  lastName: string;
  fullName: string;
}

export enum Language {
  ENGLISH = 'en',
  GERMAN = 'de',
  FRENCH = 'fr',
  SPANISH = 'es'
}

export enum Timezone {
  AST = 'AST',
  EST = 'EST',
  CST = 'CST',
  PST = 'PST'
}

export const AllRoles = [UserRole.ADMIN, UserRole.USER];

export interface UserSession {
  username: string;
  role: UserRole;
}

export interface BasicResponse<T = {}> {
  success: boolean;
  data?: T;
  errorMessage?: string;
}

export enum WebsocketEventTypes {
  CONVO_UPDATE = 'conversationUpdate',
  NEW_FILE = 'new_file',
  UPDATE_FILE = 'update_file'
}

export interface WebsocketEventPayload<T> {
  type: WebsocketEventTypes;
  payload: T;
}

export interface Conversation {
  conversationId: string;
  userId: string;
  name?: string;
  status: ConversationStatus;
  createdOn: string;
  lastUpdated: string;
  messages: Message[];
  rawMessages: RawMessage[];
}

export enum ConversationStatus {
  IDLE = 'idle',
  PROCESSING = 'processing',
  ARCHIVED = 'archived'
}

export enum MessageStatus {
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  PROCESSING = 'processing',
  ERROR = 'error'
}

export enum MessageRole {
  USER = 'user',
  SYSTEM = 'system',
  ASSISTANT = 'assistant'
}

export interface WorkLog {
  text: string;
  command?: {
    name: string;
    arguments: { [key: string]: string };
    response: string;
  };
  timestamp: string;
}

export interface Message {
  role: MessageRole;
  content: string;
  status: MessageStatus;
  timestamp: string;
  workLog?: WorkLog[];
  sources?: string;
}

export interface RawMessage {
  role: MessageRole;
  content: string;
  sources?: string;
  timestamp: string;
}

export interface Document {
  documentId: string;
  hash: string;
  objectKey: string;
  orgId: string;
  fileName: string;
  fileType: string;
  fileSize: number;
  bucketName: string;
  dateUploaded: string;
  lastUpdated: string;
  source: DocumentSource;
  sourceId?: string;
  uploadedByUser: string;
  permissionGroups: PermissionGroup[];
  vectorIds: string[];
}

export interface PermissionGroup {
  key: string;
  effect: 'allow' | 'deny';
}

export enum DocumentSource {
  DIRECT_UPLOAD = 'directUpload',
  CONFLUENCE = 'confluence',
  NOTION = 'notion',
  GOOGLE_DRIVE = 'googledrive',
  UNKNOWN = 'unknown'
}

export type Undefinable<T> = T | undefined;

export interface Organization {
  orgId: string;
  orgName: string;
  ownerId: string;
  createdOn: string;
  members: OrgMember[];
  invites: OrgInvite[];
  permissionGroups: OrgPermissionGroup[];
  integrationIds: string[];
  settings: OrgSettings;
  companyIndustry: OrgIndustry;
  companySize: OrgSize;
  subscriptionType: SubscriptionType;
}

export enum OrgSize {
  SOLO = 'solo',
  MICRO = 'micro',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  ENTERPRISE = 'enterprise'
}

export enum OrgIndustry {
  AGRICULTURE = 'agriculture',
  AUTOMOTIVE = 'automotive',
  CONSTRUCTION = 'construction',
  EDUCATION = 'education',
  FINANCE = 'finance',
  HEALTHCARE = 'healthcare',
  HOSPITALITY = 'hospitality',
  MANUFACTURING = 'manufacturing',
  MEDIA = 'media',
  REAL_ESTATE = 'real_estate',
  RETAIL = 'retail',
  TECHNOLOGY = 'technology',
  TRANSPORTATION = 'transportation',
  OTHER = 'other'
}

export enum SubscriptionType {
  FREE = 'free',
  BASIC = 'basic',
  PRO = 'pro',
  ENTERPRISE = 'enterprise'
}

export interface OrgSettings {
  language: Language;
  timezone: Timezone;
  profanityFilterLevel: 'high' | 'medium' | 'low';
}

export interface OrgMember {
  userId: string;
  role: OrgRole;
}

export interface OrgPermissionGroup {
  groupId: string;
  name: string;
  userIds: string[];
  createdOn: string;
}

export enum OrgRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  USER = 'user'
}

export interface OrgInvite {
  inviteId: string;
  email: string;
  role: OrgRole;
  createdOn: string;
  status: OrgInviteStatus;
}

export enum OrgInviteStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted'
}

export interface ConfluenceSpace {
  name: string;
  key: string;
  id: number;
  type: string;
  homepageId: number;
  status: 'current' | 'archived';
  description: string | null;
}

export const acceptedFileExtensions = ['pdf', 'txt', 'md', 'csv'];

export enum JobType {
  SCHEDULED_SYNC = 'scheduledSync',
  ONE_TIME_SYNC = 'oneTimeSync'
}
