import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import 'sanitize.css/sanitize.css';
import ScrollTop from './hooks/useScrollTop';

import { createBrowserHistory } from 'history';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import { SidebarProvider } from './providers/SidebarContext';

const app = document.getElementById('root') as HTMLElement;

if (app) {
  const history = createBrowserHistory();

  // eslint-disable-next-line no-restricted-globals
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  const root = ReactDOM.createRoot(app);
  root.render(
    <HelmetProvider>
      <SidebarProvider>
        <CookiesProvider>
          <BrowserRouter>
            <ScrollTop />
            <App />
          </BrowserRouter>
        </CookiesProvider>
      </SidebarProvider>
    </HelmetProvider>
  );
}
