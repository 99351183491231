import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import BaseLayout from 'app/layouts/BaseLayout';

import { PrivacyPolicy } from 'app/pages/Policies/PrivacyPolicy';
import Status404 from 'app/pages/Status/Status404';
import Status500 from 'app/pages/Status/Status500';
import SuspenseLoader from './app/components/SuspenseLoader';

const Loader = Component => props =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const HomePage = Loader(lazy(() => import('app/pages/HomePage')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: 'privacy',
        element: <PrivacyPolicy />
      },
      {
        path: '404',
        element: <Status404 />
      },
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: '/*',
        element: <Navigate to="404" replace />
      }
    ]
  }
];

export default routes;
